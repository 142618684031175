/* eslint-disable no-const-assign */
import { React, useEffect, useState } from "react";
import { Modal, Form, ModalFooter } from "react-bootstrap";
import API, { DOMAIN_URL } from "../../services/api";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import classes from "./Login.module.css";

const PASSWORD_REGEX = {
  short: /.{8,}/,
  numeric: /[0-9]/,
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  special: /[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\`\~\[\{\]\}\\\|\;\:\'\"\,\<\.\>\/\?]/,
};

export default function ResetPasswordPage() {
  const defaultPasswordState = {
    value: "",
    visible: false,
  };

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token") || "";

  const [firstPassword, setFirstPassword] = useState(defaultPasswordState);
  const [secondPassword, setSecondPassword] = useState(defaultPasswordState);

  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const confirmChange = () => {
    if (
      firstPassword.value === secondPassword.value &&
      /^(?=.*\d*)(?=.*[!=@#$%^&*()_+,./:";'])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
        firstPassword.value
      )
    )
      API.post(`/auth/identity/resetpassword?token=${token}`, {
        password: firstPassword.value,
        passwordConfirm: secondPassword.value,
      }).then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          window.location.replace(DOMAIN_URL);
        }, 5000);
      });
  };

  const validate = () =>
    API.get(`/auth/identity/resetpassword?token=${token}`)
      .then((response) => {
        setIsTokenValid(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });

  useEffect(() => {
    setIsLoading(true);
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  return (
    <div>
      <Modal show={isTokenValid} dialogClassName="_g-modal-dialog_">
        <Modal.Header className="border-0" closeButton />
        <Modal.Body style={{ width: "100%", minHeight: "40vh" }}>
          <div>
            <h3
              className="m-auto"
              style={{ width: "100%", textAlign: "center", color: "#39b3cc" }}
            >
              Create New Password
            </h3>
            <Form className="loginInput mt-5" style={{ position: "relative" }}>
              <div>
                {firstPassword.value.length > 0 &&
                  !PASSWORD_REGEX.short.test(firstPassword.value) && (
                    <div>
                      <span className={classes["error-feedback"]}>
                        Must be at least 8 characters.
                      </span>
                    </div>
                  )}
                {firstPassword.value.length > 0 &&
                  !PASSWORD_REGEX.special.test(firstPassword.value) && (
                    <div>
                      <span className={classes["error-feedback"]}>
                        Must contains at least 1 special characters.
                        <br />
                      </span>
                    </div>
                  )}
                {firstPassword.value.length > 0 &&
                  !PASSWORD_REGEX.lowercase.test(firstPassword.value) && (
                    <div>
                      <span className={classes["error-feedback"]}>
                        Must contains at least 1 lowercase letter.
                        <br />
                      </span>
                    </div>
                  )}
                {firstPassword.value.length > 0 &&
                  !PASSWORD_REGEX.uppercase.test(firstPassword.value) && (
                    <div>
                      <span className={classes["error-feedback"]}>
                        Must contains at least 1 uppercase letter.
                        <br />
                      </span>
                    </div>
                  )}
              </div>
              <div className={classes.wrapper}>
                <input
                  placeholder="Enter new password"
                  style={{ width: "inherit" }}
                  type={firstPassword.visible ? "text" : "password"}
                  // id="resetPassword"
                  onChange={(e) =>
                    setFirstPassword({
                      ...firstPassword,
                      value: e.target.value,
                    })
                  }
                />
                <div
                  className={
                    firstPassword.visible === true
                      ? "icon-visible"
                      : "icon-invisible"
                  }
                  onClick={() =>
                    setFirstPassword({
                      ...firstPassword,
                      visible: !firstPassword.visible,
                    })
                  }
                />
              </div>
              <div className={classes.wrapper}>
                <input
                  placeholder="Re-enter password"
                  type={secondPassword.visible ? "text" : "password"}
                  id="resetPassword"
                  style={{ width: "inherit" }}
                  onChange={(e) => {
                    setSecondPassword({
                      ...secondPassword,
                      value: e.target.value,
                    });
                  }}
                />
                <div
                  className={
                    secondPassword.visible === true
                      ? "icon-visible"
                      : "icon-invisible"
                  }
                  onClick={() =>
                    setSecondPassword({
                      ...secondPassword,
                      visible: !secondPassword.visible,
                    })
                  }
                />
              </div>
              {firstPassword.value !== secondPassword.value ? (
                <span className={classes["error-feedback"]}>
                  Both passwords must match.
                </span>
              ) : null}
              <div className="loginButton" style={{ marginTop: "15px" }}>
                <button
                  type="button"
                  style={{ width: "100%", backgroundColor: "#39b3cc" }}
                  onClick={confirmChange}
                >
                  Confirm
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <ModalFooter className="_g-modal-footer_">
          <img
            className="caipaLogo"
            alt="loaginPageCaipaLogo"
            src="../../image/portal_CAIPA_Foundation.png"
          />
        </ModalFooter>
      </Modal>
      {/* updated page */}
      <Modal show={showSuccess === true} dialogClassName="_g-modal-dialog_">
        <Modal.Header className="border-0" closeButton />
        <Modal.Body style={{ minHeight: "40vh" }}>
          <div id="testchange">
            <div
              className="m-auto"
              style={{ width: "70%", textAlign: "center" }}
            >
              <img
                alt="checkcircle"
                src="../../image/Check_Circle.png"
                style={{ width: "100px", marginBottom: "150px" }}
              />
              <h3>You Are All Set!</h3>
              <p>Redirect to Login in page in 5 second</p>
            </div>
          </div>
        </Modal.Body>
        <ModalFooter className="_g-modal-footer_">
          <img
            className="caipaLogo"
            alt="loaginPageCaipaLogo"
            src="../../image/portal_CAIPA_Foundation.png"
          />
        </ModalFooter>
      </Modal>
      {/* /*expire page */}
      <Modal show={!isTokenValid}>
        <Modal.Header className="border-0">
          {/* <a
            href="https://bdportal.caipa.com"
            style={{ color: "#004990", fontWeight: "bold" }}>
            Home
          </a> */}
        </Modal.Header>
        <Modal.Body>
          <div id="testchange">
            <p
              className="m-auto"
              style={{ width: "70%", textAlign: "center", color: "black" }}
            >
              Link is no longer valid, please contact IT suppourt at
              itsupport@caipa.com!
            </p>
            <div
              style={{
                color: "black",
                width: "100%",
                margin: "100px 0",
                fontSize: "larger",
                textAlign: "center",
              }}
            >
              Back to{" "}
              <a
                href={DOMAIN_URL}
                style={{ color: "#39b3cc", fontWeight: "bold" }}
              >
                Home
              </a>
            </div>
          </div>
        </Modal.Body>
        <ModalFooter className="_g-modal-footer_">
          <img
            className="caipaLogo"
            alt="loaginPageCaipaLogo"
            src="../../image/portal_CAIPA_Foundation.png"
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
